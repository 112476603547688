:root {
  --color-bg-primary: #1e1e1e;
  --color-fg-primary: #aaaaaa;
  --color-springy-primary: lime;
}

h1,
h2,
p,
button,
a,
div {
  color: var(--color-fg-primary);
  text-decoration: none;
}

body {
  background-color: var(--color-bg-primary);
  padding: 1rem;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.pane {
  width: 100%;

  border: 1px solid #eeeeee;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 1rem;

  padding: 1rem;
}

.pane > * {
  flex: 1 1 50%;
}

.pane > .animation-window {
  position: relative;
  height: 80px;
  background-color: #000000;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.springy {
  background-color: var(--color-springy-primary);
  height: 80px;
  width: 80px;
  border-radius: 8px;

  position: absolute;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #131313;
}

.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}
